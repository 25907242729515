import axios from 'axios'
import SaveLead from 'core/SaveLead'


window.document.addEventListener('DOMContentLoaded', async function (event) {
	const all_forms = document.getElementsByClassName('ModalForm1');

	for(let i=0; i< all_forms.length;i++){
		all_forms[i].addEventListener("submit", function (e) {
			e.preventDefault()
			let formData = new FormData(e.currentTarget)
			formData = Object.fromEntries(formData.entries());
			var name = formData['fullname'];
			var email = formData['email']?formData['email']:null;
			var number = formData['phoneno'];
			var country_code = formData['country-code'];
			var projectId = formData['project-id'];
			let form_data = window.$(".ModalForm1").data();
			SaveLead(name, email, number, country_code , form_data.id+'_equiry_form',projectId);
		})
	}	

	window.$('#BannerForm');
		$('.ModalForm1').data({
			id: "banner"
		})
		
	setTimeout(function() {
		var head_ID = document.getElementsByTagName("head")[0];
		var script_element = document.createElement('script');
		script_element.type = 'text/javascript';
		script_element.src = '/static/js/vendors/app.js';
		head_ID.appendChild(script_element);
	}, 2000);

})

